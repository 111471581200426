import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "review-jobs-page__grid" }
const _hoisted_3 = { class: "review-jobs-page__performer-name" }
const _hoisted_4 = { class: "my-8 text-center" }
const _hoisted_5 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_ReviewRequestsCounter = _resolveComponent("ReviewRequestsCounter")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ResultCard = _resolveComponent("ResultCard")!
  const _component_PageLayout = _resolveComponent("PageLayout")!
  const _component_RequestPopUpModal = _resolveComponent("RequestPopUpModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageLayout, {
      onOnBack: _ctx.$router.back,
      class: "review-jobs-page",
      "back-button": "",
      stepBarOptions: _ctx.stepBarOptions
    }, {
      default: _withCtx(() => [
        (_ctx.loading && !_ctx.requestsFetchMoreLoading)
          ? (_openBlock(), _createBlock(_component_a_skeleton, {
              key: 0,
              active: ""
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_a_typography_title, {
                  level: 2,
                  class: "mt-28 mb-8"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Step 1: Choose your preferred videos")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ReviewRequestsCounter, {
                  current: _ctx.selectedRequestIds.length,
                  max: _ctx.maxVideoCount,
                  class: "mb-28"
                }, null, 8, ["current", "max"])
              ]),
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.requests, (request) => {
                  return (_openBlock(), _createBlock(_component_ResultCard, {
                    key: request?.id,
                    id: request?.id,
                    title: 
            _ctx.t('Video #{number}', {
              number: request?.id,
            })
          ,
                    "thumbnail-src": 
            _ctx.getVimeoThumbnail(request?.outputVideos?.at(0)?.embedUrl)
          ,
                    "select-disabled": 
            !_ctx.isAdditionalSelectionAllowed &&
            !_ctx.selectedRequestIds.includes(request?.id ?? '')
          ,
                    selected: _ctx.selectedRequestIds.includes(request?.id ?? ''),
                    onOnSelect: _ctx.handleSelectedChange,
                    selectable: "",
                    "hide-new-badge": "",
                    "hide-mask": ""
                  }, _createSlots({
                    extra: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "link",
                        onClick: _withModifiers(($event: any) => (_ctx.showRequestModal(request)), ["stop"]),
                        class: "p-0 h-auto"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Details")), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, [
                    (request?.performer?.nickname)
                      ? {
                          name: "subtitle",
                          fn: _withCtx(() => [
                            _createVNode(_component_i18n_t, { keypath: "Uploaded by {name}" }, {
                              name: _withCtx(() => [
                                _createElementVNode("span", _hoisted_3, _toDisplayString(request?.performer?.nickname), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        }
                      : undefined
                  ]), 1032, ["id", "title", "thumbnail-src", "select-disabled", "selected", "onOnSelect"]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_4, [
                (_ctx.requestsFetchMoreLoading)
                  ? (_openBlock(), _createBlock(_component_a_skeleton, {
                      key: 0,
                      active: ""
                    }))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_ReviewRequestsCounter, {
                  current: _ctx.selectedRequestIds.length,
                  max: _ctx.maxVideoCount,
                  class: "mt-16 mb-20"
                }, null, 8, ["current", "max"]),
                _createElementVNode("div", null, [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    class: "mb-32",
                    disabled: !_ctx.isSelectionValid,
                    onClick: _ctx.handleFinishStep1,
                    size: "large"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Next")), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ])
              ])
            ], 64))
      ]),
      _: 1
    }, 8, ["onOnBack", "stepBarOptions"]),
    _createVNode(_component_RequestPopUpModal, {
      visible: _ctx.requestPopUpVisible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.requestPopUpVisible) = $event)),
      request: _ctx.requestPopUpObject,
      "hide-status-buttons": ""
    }, null, 8, ["visible", "request"])
  ], 64))
}