
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import ResultCard from "@/shared/components/ResultCard.vue";
import { useReviewRequests } from "@/shared/composables/useReviewRequests";
import { useVimeoEmbed } from "@/shared/composables/useVimeoEmbed";
import { useRequestPopUpModal } from "@/shared/composables/useRequestPopUpModal";
import routeNames from "@/web/router/routeNames";
import RequestPopUpModal from "@/web/views/Requests/RequestPopUpModal.vue";
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { OrderStatus } from "@/shared/types/OrderStatus";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import { useStepBar, StepBarLocation } from "@/shared/composables/useStepBar";
import message from "@/shared/utils/message";
import delay from "lodash/delay";
import ReviewRequestsCounter from "@/web/views/Requests/ReviewRequestsCounter.vue";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const { redirectToDownloadRequests, redirectToOrderDetails } =
      useRedirectHelper();

    const orderId = ref(String(route.params.orderId));

    const {
      requests,
      requestsFetchMore,
      requestsFetchMoreLoading,
      requestsHasNextPage,
      refetchRequests,
      loading,
      selectedRequestIds,
      maxVideoCount,
      isSelectionValid,
      isAdditionalSelectionAllowed,
      handleSelectedChange,
      handleFinishStep1,
      preselectApprovedRequests,
    } = useReviewRequests(orderId);

    const { getVimeoThumbnail } = useVimeoEmbed();

    // === Request Pop Up Modal ===
    const { requestPopUpObject, requestPopUpVisible, showRequestModal } =
      useRequestPopUpModal();

    watch(requests, () => {
      if (requests.value?.length) {
        if (!selectedRequestIds.value.length) {
          // Redirect to Download page, or Order Details, based on Order Status
          const orderStatus = requests.value[0]?.order?.status?.key;
          if (
            orderStatus == OrderStatus.CLIENT_FEEDBACK ||
            orderStatus == OrderStatus.DONE
          ) {
            return redirectToDownloadRequests({ orderId: orderId.value });
          } else if (orderStatus != OrderStatus.CLIENT_REVIEW) {
            redirectToOrderDetails({ orderId: orderId.value });

            return message["warning"](
              t("The order is not yet ready for review")
            );
          }
        }

        // Preselect videos if not defined
        preselectApprovedRequests();
      }
    });

    const { options: stepBarOptions } = useStepBar(StepBarLocation.requests, 0);

    watch(requests, () => {
      if (requestsHasNextPage.value) {
        // Add delay to fix issue where whole requests list is loading
        delay(requestsFetchMore, 1);
      }
    });

    return {
      t,
      routeNames,
      getVimeoThumbnail,

      requestPopUpObject,
      requestPopUpVisible,
      showRequestModal,

      requests,
      requestsFetchMore,
      requestsFetchMoreLoading,
      requestsHasNextPage,
      refetchRequests,
      loading,
      selectedRequestIds,
      maxVideoCount,
      isSelectionValid,
      isAdditionalSelectionAllowed,
      handleSelectedChange,
      handleFinishStep1,
      stepBarOptions,
    };
  },
  components: {
    PageLayout,
    ResultCard,
    RequestPopUpModal,
    ReviewRequestsCounter,
  },
});
