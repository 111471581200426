
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    current: Number,
    max: Number,
  },
  setup(props) {
    const { t } = useI18n();

    const isComplete = computed(() => (props.current ?? 0) == (props.max ?? 0));

    return { t, isComplete };
  },
});
