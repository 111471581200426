import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "review-requests-counter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_text, { class: "text-tag" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Selected Videos")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_title, {
      level: 4,
      class: _normalizeClass(["review-requests-counter-count", [_ctx.isComplete && 'complete']])
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("{selectedCount} / {maxCount}", {
          selectedCount: _ctx.current ?? 0,
          maxCount: _ctx.max ?? 0,
        })), 1)
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}